/* ==========================================================================
    ANCHORS
   ========================================================================== */

a {
  /*
    Create better-looking links in browsers that support them
   */
  text-decoration-skip-ink: auto;

  @apply text-blue-600;
}