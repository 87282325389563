/* ==========================================================================
    TABLE DATA
   ========================================================================== */

table.data td,
table.data th {
    @apply border-b border-gray-400;
}

table.data th {
    @apply border-t;
}