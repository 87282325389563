/* ==========================================================================
    HORIZONTAL RULE
   ========================================================================== */

hr {
  @apply border-none;
  @apply h-px;
  @apply p-0;
  @apply bg-gray-300;
}
