/* ==========================================================================
    PARAGRAPHS
   ========================================================================== */

p {
  orphans: 3;
  widows: 3;
  @apply mb-5;
}

p:last-child {
  @apply mb-0;
}

.lede p:first-of-type {

}
