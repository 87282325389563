/* ==========================================================================
    BLOCKQUOTES
   ========================================================================== */

blockquote {
    margin: 0;
}

blockquote cite {
    text-decoration: none;
    font-style: normal;
}
