/* Libs */
@import 'libs/swiper';

/* Tailwind */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Elements */
@import 'elements/abbr';
@import 'elements/address';
@import 'elements/anchor';
@import 'elements/blockquote';
@import 'elements/body';
@import 'elements/cite';
@import 'elements/code';
@import 'elements/figure';
@import 'elements/form';
@import 'elements/headings';
@import 'elements/html';
@import 'elements/image';
@import 'elements/lists';
@import 'elements/nav';
@import 'elements/paragraphs';
@import 'elements/rule';
@import 'elements/strong';
@import 'elements/sub-super';
@import 'elements/swiper';
@import 'elements/table';

/* Utilities */
@import 'utilities/aspect-ratio';
@import 'utilities/center';
@import 'utilities/hide';
@import 'utilities/table-data';

/* Components */
@import 'components/button';
@import 'components/header';
@import 'components/modal';
@import 'components/option-select';
@import 'components/spinner';

/* Tailwind utilities */
@import 'tailwindcss/utilities';
@import 'utilities/max-h';
