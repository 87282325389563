/* ==========================================================================
    SUPER AND SUBSCRIPTS
   ========================================================================== */
sup {
  font-variant-position: super;
}

sub,
.sinf {
  font-size: inherit;
  font-variant-position: sub;
}
