.spinner {
    @apply flex justify-between items-center w-10 h-10 text-base;
}

.spinner > div {
    @apply bg-gray-500 rounded;
    height: 100%;
    width: 15%;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .spinner-2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .spinner-3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .spinner-4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .spinner-5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}

.spinner-overlay {
    @apply absolute top-0 left-0 right-0 bottom-0 justify-center items-center hidden;
    background-color: rgba(255, 255, 255, .75);
}

.spinner-overlay.htmx-request,
.spinner-overlay.spinner-overlay--show {
    @apply flex;
}
