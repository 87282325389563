@responsive {
    .center {
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
    }
    .center-y {
        top: 50%;
        transform: translateY( -50% );
    }
    .center-x {
        left: 50%;
        transform: translateX( -50% );
    }
}