/* ==========================================================================
    IMAGES
   ========================================================================== */

/* Fluid images. */
img {
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
