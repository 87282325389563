.hide {
    top: -99999px;
    left: -99999px;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease-in-out;
}

.visually-hidden-checkbox {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    height: 2rem;
    width: 2rem;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}